import React from "react";
import sk from "../../Assets/Image/Clients/sk.png";
import tata from "../../Assets/Image/Clients/tata.png";
import vnc from "../../Assets/Image/Clients/vnc.png";

const ClientsLogo = () => {
  return (
    <div>
      <div className="text-center p-12 font-Caladea font-semibold lg:text-5xl md:text-5xl text-4xl">
        Our Clients
      </div>
      <div className="flex flex-wrap items-center lg:gap-0 md:gap-0 gap-14 justify-evenly lg:pb-20 md:pb-14 pb-10">
        <div className="p-2">
          <img src={tata} alt="TATA Steel" className="w-[200px] h-auto" />
        </div>
        <div className="p-2">
          <img src={vnc} alt="VNC" className="w-[200px] h-auto" />
        </div>
        <div className="p-2">
          <img src={sk} alt="SK Ready Mix" className="w-[200px] h-auto" />
        </div>
      </div>
    </div>
  );
};

export default ClientsLogo;
