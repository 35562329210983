import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen";
import AboutUsScreen from "./Screens/AboutUsScreen";
import ServiceScreen from "./Screens/ServiceScreen";
import ProjectScreen from "./Screens/ProjectScreen";
import ClientsScreen from "./Screens/ClientsScreen";
import WorkshopScreen from "./Screens/WorkshopScreen";
import ContactUs from "./Screens/ContactUsScreen";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import "leaflet/dist/leaflet.css";
import SplashScreen from "./Components/SplashScreen/SplashScreen";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1700);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading ? (
        <SplashScreen />
      ) : (
        <Router>
          <ScrollToTop />
          <Route>
            <Route path="/" exact component={HomeScreen} />
            <Route path="/about" exact component={AboutUsScreen} />
            <Route path="/service" exact component={ServiceScreen} />
            <Route path="/project" exact component={ProjectScreen} />
            <Route path="/clients" exact component={ClientsScreen} />
            <Route path="/workshop" exact component={WorkshopScreen} />
            <Route path="/contact-us" exact component={ContactUs} />
          </Route>
        </Router>
      )}
    </div>
  );
};

export default App;
