import React from "react";
import { Link } from "react-router-dom";
import image from "../../Assets/Image/Clients/Clients1.jpg";
import ImageGroup from "../../Assets/Image/Clients/Group 95.png";

const ClientHeader = () => {
  return (
    <div className="lg:p-0 md:p-10 p-5 lg:mb-0 md:mb-10 mb-5">
      <div className="flex lg:flex-row md:flex-col flex-col">
        <div className="lg:p-20 md:p-0 lg:mt-14">
          <h1 className="lg:text-5xl md:text-5xl text-4xl font-Caladea font-600 tracking-wide lg:leading-snug md:leading-snug">
            Meet our valued{" "}
            {/* <span className="text-[#FF0000] font-Pacifico">clients</span>: */}
            <span className="text-[#db1822] font-Pacifico">clients</span>:
            partnerships build on trust and success.
          </h1>

          <Link to="/contact-us">
            <button className="bg-[#FF0000] text-white lg:text-xl md:text-xl text-lg font-Lato lg:px-12 md:px-12 px-5 lg:py-4 md:py-4 py-2 rounded-md lg:mt-10 md:mt-10 mt-8">
              Get in Touch
            </button>
          </Link>
        </div>

        <div>
          <img
            src={ImageGroup}
            alt="images"
            className="lg:w-[100%] md:w-[100%] md:hidden hidden lg:block"
          />

          <img
            src={image}
            alt="images"
            className="lg:w-0 md:w-[100%] lg:hidden md:block hidden mt-10 rounded-xl"
          />

          <img
            src={image}
            alt="images"
            className="lg:w-0 md:w-[100%] lg:hidden md:hidden block mt-10 rounded-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default ClientHeader;
