import React from "react";
import image from "../../Assets/Image/HomeScreen/DesignEfficiency/Design Efficiency.png";
import { PiMoneyFill } from "react-icons/pi";
import { FaRegUser } from "react-icons/fa6";
// import { FaRegUser } from "react-icons/fa";

const DesignEfficiency = () => {
  return (
    <div>
      <div className="flex lg:flex-row md:flex-col flex-col items-center lg:px-20 md:px-10 px-5 lg:mt-0 md:mt-5 mt-10 justify-between gap-20 mb-20">
        <div className="lg:p-10 md:p-0 lg:w-[60%] md:w-full w-full">
          <h1 className="lg:text-5xl md:text-5xl text-4xl font-bold font-Caladea">
            Design Efficiency
          </h1>
          {/* < className="lg:text-xl md:text-xl text-lg font-Lato mt-10 leading-loose text-justify"> */}
          <p className="text-xl font-Lato lg:mt-10 md:mt-8 mt-5  leading-loose text-justify">
            Crafting Cost-Effective Structural Solutions Together: We understand
            the importance of budget in your project. Through close
            collaboration, we leverage our expertise to craft innovative,
            cost-conscious designs that meet your specific needs and maximize
            your investment.
          </p>

          <hr className="w-full border-t-2 border-gray-300 my-10" />

          <div className="flex md:flex-row lg:flex-row flex-col lg:justify-around md:justify-start gap-10">
            <div className="flex flex-col gap-2 lg:w-full md:w-72 w-full">
              <PiMoneyFill
                color="#FF0000"
                className="md:text-7xl lg:text-6xl text-7xl"
              />
              <h2 className="lg:text-3xl md:text:3xl text-4xl font-medium w-full font-Caladea tracking-wide">
                Efficiency & Savings
              </h2>
              <p className="lg:text-base md:text-base text-lg font-Lato md:w-3/4 lg:w-full w-full mt-2 text-justify">
                We create beautiful, budget-conscious structural design. We
                achieve design efficiency to deliver with great budget
              </p>
            </div>

            <div className="flex flex-col gap-2 lg:w-full md:w-72 w-full">
              <FaRegUser
                className="md:text-7xl lg:text-6xl text-7xl"
                color="#FF0000"
              />
              <h2 className="lg:text-3xl md:text:3xl text-4xl font-medium w-full font-Caladea tracking-wide lg:mt-0 md:mt-0 mt-2">
                Expert Innovation
              </h2>
              <p className="lg:text-base md:text-base text-lg font-Lato md:w-3/4 lg:w-full w-full mt-2 text-justify">
                Our team combines experience with cutting-edge tools to deliver
                cost-effective designs.
              </p>
            </div>
          </div>
        </div>

        <div className="lg:w-[40%] md:w-full w-full">
          <img
            src={image}
            alt="images"
            className="lg:w-[100%] md:w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default DesignEfficiency;
