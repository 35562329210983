import React from "react";
import image from "../../Assets/Image/AboutUs/SenthilKumar.jpeg";

const AboutDirector = () => {
  return (
    <div className="mb-10">
      <h1 className="lg:text-5xl md:text-5xl text-4xl font-Caladea font-600 tracking-wide text-center">
        About Director
      </h1>
      <div className="lg:px-16 md:px-10 px-5">
        <div className="flex flex-col lg:mt-10 md:mt-10 mt-5 gap-2">
          <h1 className="lg:text-2xl md:text-xl text-lg font-Caladea tracking-wide font-600">
            Dr. Senthilkumar R
          </h1>
          <p className="lg:text-lg md:text-xl text-base font-Lato">
            Director - SENVIRA Engineering Pvt. Ltd.
          </p>
          <p className="lg:text-lg md:text-xl text-base font-Lato">
            Assistant Professor, Department of Civil Engineering, NIT Tiruchirapalli
          </p>
          <hr className="border-t-4 border-[#FF0000] w-24 mt-3" />
        </div>

        <div className="flex lg:flex-row md:flex-col flex-col justify-center items-center">
          <div className="flex lg:mt-10 md:mt-5 mt-5">
            <img
              src={image}
              alt="images"
              className="h-auto"
              style={{ width: "1000px" }}
            />
          </div>

          <div className="lg:px-5 md:px-0">
            <p className="lg:mt-10 md:mt-5 mt-5 lg:text-lg md:text-2xl text-lg font-Lato text-justify md:leading-loose lg:leading-relaxed">
              With a visionary drive to spearhead revolutionary advancements in
              the engineering domain, our esteemed director embarked on a
              journey that commenced with an illustrious education in Structural
              Engineering at Anna University, Chennai. This was followed by the
              attainment of a prestigious Doctorate (PhD) specializing in
              earthquake-resistant design and steel structures from the renowned
              Indian Institute of Technology Madras (IITM), Chennai.
            </p>

            <p className="lg:mt-10 md:mt-5 mt-5 lg:text-lg md:text-2xl text-lg font-Lato text-justify md:leading-loose lg:leading-relaxed">
              After working as an Assistant Engineer in the Tamil Nadu Rural
              Development & Panchayat Raj Department, he pursued his passion for
              research and teaching. He then transitioned to a faculty position
              at Vellore Institute of Technology (VIT) and later became an
              Assistant Professor at the prestigious National Institute of
              Technology Tiruchirappalli (NITT), our director s expertise
              flourished. This culminated in the meticulous review and
              validation (structural design vetting, structural stability check,
              third-party quality monitoring) of over 200+ civil projects
              spanning diverse sectors such as Indian Railway, Airport Authority
              of India, CPWD, PWD, ESIC, and others.
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:mt-10 md:mt-5 mt-5 gap-2">
          <p className="lg:text-lg md:text-2xl text-lg font-Lato text-justify md:leading-loose lg:leading-relaxed">
            Recognizing the intricate web of standards and specifications
            inherent in the construction industry, our director s profound
            technical prowess and practical experience ignited the inception of
            SENVIRA Engineering Private Limited in 2021. With a noble vision to
            provide cost-effective housing solutions for low and middle-income
            groups, SENVIRA boasts a team of seasoned experts under the adept
            leadership of our director. We envision a future where every
            structure is engineered with utmost precision, resilience, and
            environmental consciousness, ensuring the safety and well-being of
            present and future generations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutDirector;
