import React from "react";
import image from "../../Assets/Image/ContactUs/Header.png";
import ImageGroup from "../../Assets/Image/ContactUs/Group 99.png";

const ContactUsHeader = () => {
  return (
    <div className="lg:p-0 md:p-10 p-5 lg:mb-20 md:mb-10 mb-5">
      <div className="flex lg:flex-row md:flex-col flex-col">
        <div className="lg:p-20 md:p-0 lg:mt-14">
          <h1 className="lg:text-6xl md:text-6xl text-4xl font-Caladea font-600 lg:leading-snug md:leading-snug">
            Reach out today to discuss your project requirement with us
          </h1>

          {/* <button className="bg-[#FF0000] text-white lg:text-xl md:text-xl text-lg font-medium lg:px-12 md:px-12 px-5 lg:py-4 md:py-4 py-2 rounded-md lg:mt-10 md:mt-10 mt-8">
            Get in Touch
          </button> */}
        </div>

        <div>
          <img
            src={ImageGroup}
            alt="images"
            className="lg:w-[100%] md:w-[100%] md:hidden hidden lg:block"
          />

          <img
            src={image}
            alt="images"
            className="lg:w-0 md:w-[100%] lg:hidden md:block hidden mt-10 rounded-xl"
          />

          <img
            src={image}
            alt="images"
            className="lg:w-0 md:w-[100%] lg:hidden md:hidden block mt-10 rounded-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUsHeader;
