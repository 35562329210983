import React from "react";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/NavBar/NavBar";
import ContactUsHeader from "../Components/ContactUs/ContactUsHeader";
import GetInTouch from "../Components/ContactUs/GetInTouch";
import Map from "../Components/ContactUs/Map";

import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <div>
      <Helmet>
        <title>Senvira - Contact Us</title>
      </Helmet>
      <NavBar />
      <ContactUsHeader />
      <GetInTouch />
      <Map />
      <Footer />
    </div>
  );
};

export default ContactUs;
