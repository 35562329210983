import React from "react";
import NavBar from "../Components/NavBar/NavBar";
import HeaderHome from "../Components/HomeComponents/HeaderHome";
import WhyChooseUs from "../Components/HomeComponents/WhyChooseUs";
import DesignEfficiency from "../Components/HomeComponents/DesignEfficiency";
import IndustryTrendsetter from "../Components/HomeComponents/IndustryTrendsetter";
// import GreenSolutions from "../Components/HomeComponents/GreenSolutions";
import OurPromises from "../Components/HomeComponents/OurPromises";
import Whatourclientssays from "../Components/HomeComponents/Whatourclientssays";
import ThirukuralQuotes from "../Components/HomeComponents/ThirukuralQuotes";
import OurRemarkableProject from "../Components/HomeComponents/OurRemarkableProject";
import ContactUsToMakeProjectMemorable from "../Components/HomeComponents/ContactUsToMakeProjectMemorable";
import Footer from "../Components/Footer/Footer";

import { Helmet } from "react-helmet";

const HomeScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Senvira - Home</title>
      </Helmet>
      <NavBar />
      <HeaderHome />
      <WhyChooseUs />
      <DesignEfficiency />
      <IndustryTrendsetter />
      {/* <GreenSolutions /> */}
      <OurPromises />
      {/* <Whatourclientssays /> */}
      <ThirukuralQuotes />
      <OurRemarkableProject />
      <ContactUsToMakeProjectMemorable />
      <Footer />
    </div>
  );
};

export default HomeScreen;
