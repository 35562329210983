import React from "react";
import { HiOutlinePresentationChartLine } from "react-icons/hi";
import { MdOutlineScience } from "react-icons/md";
import { PiCraneTowerFill } from "react-icons/pi";
import { TfiRulerAlt2 } from "react-icons/tfi";

const ServiceBody = () => {
  return (
    <div>
      <div className="lg:text-5xl md:text-4xl text-3xl font-Caladea font-600 lg:p-20 md:p-10 p-5">
        Our Services
      </div>
      <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-10 lg:px-20 md:px-20 px-5 pb-24">
        <div className="bg-[#FFE1E1] rounded-3xl flex flex-col gap-5 lg:p-24 p-10">
          <div>
            <TfiRulerAlt2 size={100} color="#FF0000" />
          </div>
          <div className="font-Caladea lg:text-4xl md:text-4xl text-3xl md:w-[500px] lg:w-[500px] w-full">
            Structural Design for various Structures
          </div>
          <div className="text-[#565656] lg:text-xl md:text-xl text-xl font-Lato text-justify">
            (RCC, Steel, Steel-Concrete <br /> Composite)
          </div>
          <div className="text-[#565656] lg:text-xl md:text-xl text-xl font-Lato text-justify">
            Structural Drawings Proof checked by IIT's or NIT's will be provided
            for government Projects
          </div>
        </div>

        <div className="bg-[#FFE1E1] rounded-3xl flex flex-col gap-5 lg:p-24 p-10">
          <div>
            <PiCraneTowerFill size={100} color="#FF0000" />
          </div>
          <div className="font-Caladea lg:text-4xl md:text-4xl text-3xl md:w-[500px] lg:w-[500px] w-full">
            Structural Stability Certification
          </div>
          <div className="text-[#565656] lg:text-xl md:text-xl text-xl font-Lato text-justify">
            (Auditing Services : Visual Inspection, Load Analysis, Material
            Testing, Non-Destructive Testing (NDT))
          </div>
          <div className="text-[#565656] lg:text-xl md:text-xl text-xl font-Lato text-justify">
            Ensure the safety and longevity of your building with our
            comprehensive structural auditing services.
          </div>
          <div className="text-[#565656] lg:text-xl md:text-xl text-xl font-Lato">
            Trust us to provide a thorough evaluation, giving you peace of mind
            and a robust, secure structure for years to come.
          </div>
        </div>

        <div className="bg-[#FFE1E1] rounded-3xl flex flex-col gap-5 lg:p-24 p-10">
          <div>
            <HiOutlinePresentationChartLine size={100} color="#FF0000" />
          </div>
          <div className="font-Caladea lg:text-4xl md:text-4xl text-3xl md:w-[500px] lg:w-[500px] w-full">
            Third Party Quality Monitoring
          </div>
          <div className="text-[#565656] lg:text-xl md:text-xl text-xl font-Lato text-justify">
            (Independent Inspection, Quality Audits, Process Verification,
            Execution Cross-Check)
          </div>
          <div className="text-[#565656] lg:text-xl md:text-xl text-xl font-Lato text-justify">
            Enhance your project's integrity with our third-party quality
            monitoring services. We provide unbiased assessments to ensure
            compliance with industry standards and project specifications.
          </div>
        </div>

        <div className="bg-[#FFE1E1] rounded-3xl flex flex-col gap-5 lg:p-24 p-10">
          <div>
            <MdOutlineScience size={100} color="#FF0000" />
          </div>
          <div className="font-Caladea lg:text-4xl md:text-4xl text-3xl md:w-[500px] lg:w-[500px] w-full">
            Research & Development
          </div>
          <div className="text-[#565656] lg:text-xl md:text-xl text-xl font-Lato text-justify">
            At the forefront of innovation, our Research & Development in
            Structural Engineering focuses on advancing construction techniques
            and materials. We strive to enhance safety, sustainability, and
            efficiency in building practices. Join us in pioneering the future
            of structural engineering with groundbreaking research and
            state-of-the-art solutions.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBody;
