import React from "react";
import Navbar from "../Components/NavBar/NavBar";
import Footer from "../Components/Footer/Footer";
import ServiceHeader from "../Components/Services/ServiceHeader";
import ServiceBody from "../Components/Services/ServiceBody";

import { Helmet } from "react-helmet";

const ServiceScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Senvira - Services</title>
      </Helmet>
      <Navbar />
      <ServiceHeader />
      <ServiceBody />
      <Footer />
    </div>
  );
};

export default ServiceScreen;
