import React, { useEffect } from "react";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const WorkShopModal = ({ isOpen, onRequestClose, images }) => {
  useEffect(() => {
    const handleScroll = (e) => {
      if (isOpen) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    };

    if (isOpen) {
      window.addEventListener("scroll", handleScroll, { passive: false });
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.style.overflow = "auto";
    };
  }, [isOpen, onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="rounded-lg max-w-3xl mx-auto my-8 p-5 z-30 bg-gray-600 bg-opacity-100"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <div className="flex justify-end p-2">
        <button
          onClick={onRequestClose}
          className="text-white md:text-3xl text-3xl font-bold"
        >
          &times;
        </button>
      </div>
      <Carousel
        className="lg:w-full md:w-full w-[90vw] h-auto"
        useKeyboardArrows={true}
        showArrows={true}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="flex justify-center items-center align-middle self-center lg:h-[75vh] md:h-[60vh] h-[40vh] w-[85vw] md:w-full lg:w-[40vw] mx-auto"
          >
            <img
              src={image}
              alt={`Slide ${index}`}
              style={{
                width: "100%",
                height: "auto",
                margin: "0 auto",
                objectFit: "fill",
              }}
              // className="w-full h-full object-cover"
              className="h-auto mx-auto items-center flex justify-center"
            />
          </div>
        ))}
      </Carousel>
    </Modal>
  );
};

export default WorkShopModal;
