// import React from "react";
// import Logo from "../../Assets/Image/Navbar/Logo.png";
// import { Link, useLocation } from "react-router-dom";
// import useMenuStore from "../StateManagement/Store";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { FiX } from "react-icons/fi";

// const NavBar = () => {
//   const { setSelectedMenu } = useMenuStore();
//   const location = useLocation();

//   React.useEffect(() => {
//     // Set selected menu based on the current route
//     setSelectedMenu(location.pathname);
//   }, [location, setSelectedMenu]);

//   const handleMenuClick = (menu) => {
//     setSelectedMenu(menu);
//   };

//   const getMenuClass = (menu) =>
//     location.pathname === menu ? "text-red-500" : "text-black";

//   return (
//     <nav className="bg-white border border-[#D0CFCF] border-b-2 p-5">
//       <div className="flex flex-row justify-evenly gap-72">
//         <div className="flex flex-row gap-5">
//           <img src={Logo} alt="Logo" className="w-44 h-16" />

//           <ul className="flex flex-row items-center gap-10 px-10">
//             <li className={`cursor-pointer font-semibold ${getMenuClass("/")}`}>
//               <Link to="/" onClick={() => handleMenuClick("/")}>
//                 Home
//               </Link>
//             </li>
//             <li
//               className={`cursor-pointer font-semibold ${getMenuClass(
//                 "/about"
//               )}`}
//             >
//               <Link to="/about" onClick={() => handleMenuClick("/about")}>
//                 About us
//               </Link>
//             </li>
//             <li
//               className={`cursor-pointer font-semibold ${getMenuClass(
//                 "/service"
//               )}`}
//             >
//               <Link to="/service" onClick={() => handleMenuClick("/service")}>
//                 Service
//               </Link>
//             </li>
//             <li
//               className={`cursor-pointer font-semibold ${getMenuClass(
//                 "/project"
//               )}`}
//             >
//               <Link to="/project" onClick={() => handleMenuClick("/project")}>
//                 Project
//               </Link>
//             </li>
//             <li
//               className={`cursor-pointer font-semibold ${getMenuClass(
//                 "/clients"
//               )}`}
//             >
//               <Link to="/clients" onClick={() => handleMenuClick("/clients")}>
//                 Clients
//               </Link>
//             </li>
//             <li
//               className={`cursor-pointer font-semibold ${getMenuClass(
//                 "/workshop"
//               )}`}
//             >
//               <Link to="/workshop" onClick={() => handleMenuClick("/workshop")}>
//                 Workshop
//               </Link>
//             </li>

//             {/* <li
//               className={`cursor-pointer font-semibold ${getMenuClass(
//                 "/blog"
//               )}`}
//             >
//               <Link to="/blog" onClick={() => handleMenuClick("/blog")}>
//                 Blog
//               </Link>
//             </li> */}
//           </ul>
//         </div>

//         <div className="flex items-center">
//           <button className="bg-[#FF0000] text-white font-medium px-5 py-2 rounded-md">
//             <Link
//               to="/contact-us"
//               onClick={() => handleMenuClick("/contact-us")}
//             >
//               Contact us
//             </Link>
//           </button>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default NavBar;

import React, { useState } from "react";
import Logo from "../../Assets/Image/Navbar/Logo.png";
import { Link, useLocation } from "react-router-dom";
import useMenuStore from "../StateManagement/Store";
import { GiHamburgerMenu } from "react-icons/gi";
import { FiX } from "react-icons/fi";

const NavBar = () => {
  const { setSelectedMenu } = useMenuStore();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  React.useEffect(() => {
    // Set selected menu based on the current route
    setSelectedMenu(location.pathname);
  }, [location, setSelectedMenu]);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    setIsOpen(false); // Close menu on menu item click
  };

  const getMenuClass = (menu) =>
    location.pathname === menu ? "text-red-500" : "text-black";

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white border border-[#D0CFCF] border-b-2 p-5 font-Lato">
      <div className="flex justify-between items-center">
        <img src={Logo} alt="Logo" className="w-44 h-16" />

        <div className="lg:hidden">
          {/* Hamburger icon */}
          <button
            onClick={toggleMenu}
            className={`block text-black hover:text-black focus:text-black focus:outline-none transition-transform duration-300 ${
              isOpen ? "rotate-90" : ""
            }`}
          >
            {isOpen ? (
              <FiX
                color="black"
                className="fill-current z-50 cursor-pointer text-black md:text-3xl text-xl"
              />
            ) : (
              <GiHamburgerMenu
                color="black"
                className="flex fill-current cursor-pointer text-black md:text-3xl text-xl"
              />
            )}
          </button>

          {isOpen && (
            <div
              className="fixed inset-0 bg-black opacity-60 z-40"
              onClick={toggleMenu}
            ></div>
          )}

          {isOpen && (
            <ul className="fixed top-0 left-0 right-0 bg-white z-50 p-5 space-y-4 w-full">
              <li
                className={`cursor-pointer font-semibold ${getMenuClass("/")}`}
              >
                <Link to="/" onClick={() => handleMenuClick("/")}>
                  Home
                </Link>
              </li>
              <li
                className={`cursor-pointer font-semibold ${getMenuClass(
                  "/about"
                )}`}
              >
                <Link to="/about" onClick={() => handleMenuClick("/about")}>
                  About us
                </Link>
              </li>
              <li
                className={`cursor-pointer font-semibold ${getMenuClass(
                  "/service"
                )}`}
              >
                <Link to="/service" onClick={() => handleMenuClick("/service")}>
                  Service
                </Link>
              </li>
              <li
                className={`cursor-pointer font-semibold ${getMenuClass(
                  "/project"
                )}`}
              >
                <Link to="/project" onClick={() => handleMenuClick("/project")}>
                  Project
                </Link>
              </li>
              <li
                className={`cursor-pointer font-semibold ${getMenuClass(
                  "/clients"
                )}`}
              >
                <Link to="/clients" onClick={() => handleMenuClick("/clients")}>
                  Clients
                </Link>
              </li>
              <li
                className={`cursor-pointer font-semibold ${getMenuClass(
                  "/workshop"
                )}`}
              >
                <Link
                  to="/workshop"
                  onClick={() => handleMenuClick("/workshop")}
                >
                  Workshop
                </Link>
              </li>
              <li>
                <button className="bg-[#FF0000] text-white font-semibold px-5 py-2 rounded-md w-full">
                  <Link
                    to="/contact-us"
                    onClick={() => handleMenuClick("/contact-us")}
                  >
                    Contact us
                  </Link>
                </button>
              </li>
            </ul>
          )}
        </div>

        {/* Lg screen components */}
        <ul className="hidden lg:flex space-x-8 font-Lato">
          <li className={`cursor-pointer font-semibold ${getMenuClass("/")}`}>
            <Link to="/" onClick={() => handleMenuClick("/")}>
              Home
            </Link>
          </li>
          <li
            className={`cursor-pointer font-semibold ${getMenuClass("/about")}`}
          >
            <Link to="/about" onClick={() => handleMenuClick("/about")}>
              About us
            </Link>
          </li>
          <li
            className={`cursor-pointer font-semibold ${getMenuClass(
              "/service"
            )}`}
          >
            <Link to="/service" onClick={() => handleMenuClick("/service")}>
              Service
            </Link>
          </li>
          <li
            className={`cursor-pointer font-semibold ${getMenuClass(
              "/project"
            )}`}
          >
            <Link to="/project" onClick={() => handleMenuClick("/project")}>
              Project
            </Link>
          </li>
          <li
            className={`cursor-pointer font-semibold ${getMenuClass(
              "/clients"
            )}`}
          >
            <Link to="/clients" onClick={() => handleMenuClick("/clients")}>
              Clients
            </Link>
          </li>
          <li
            className={`cursor-pointer font-semibold ${getMenuClass(
              "/workshop"
            )}`}
          >
            <Link to="/workshop" onClick={() => handleMenuClick("/workshop")}>
              Workshop
            </Link>
          </li>
          {/* <li>
            <button className="bg-[#FF0000] text-white font-medium px-5 py-2 rounded-md">
              <Link
                to="/contact-us"
                onClick={() => handleMenuClick("/contact-us")}
              >
                Contact us
              </Link>
            </button>
          </li> */}
        </ul>

        <ul className="hidden lg:flex space-x-8 font-Lato">
          <li>
            <button className="bg-[#FF0000] text-white font-semibold px-5 py-2 rounded-md">
              <Link
                to="/contact-us"
                onClick={() => handleMenuClick("/contact-us")}
              >
                Contact us
              </Link>
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
