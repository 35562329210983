import React from "react";
import image from "../../Assets/Image/HomeScreen/Thirukural_Quotes/Thirukural_Logo.png";

const ThirukuralQuotes = () => {
  return (
    <div>
      <div className="flex lg:flex-row md:flex-col flex-col items-center lg:px-20 md:px-10 px-5 lg:justify-between md:justify-center lg:mb-20 md:mb-10 mb-5">
        <div className="lg:ml-20 md:ml-0 w-[70%]">
          <img
            src={image}
            alt="images"
            className="w-auto h-auto"
            // style={{ width: "70%" }}
          />
        </div>

        <div className=" w-[100%] lg:mr-36 md:mr-0 flex flex-col lg:items-start md:items-center">
          <p className="lg:text-xl md:text-xl text-sm font-Caladea font-600">
            ஒல்லும் வகையான் அறவினை ஓவாதே <br /> செல்லும்வாய் எல்லாஞ் செயல்.
          </p>

          <p className="md:text-xl lg:text-xl text-base font-Lato mt-5">
            The right thing to do is to be righteous at all places and all times
          </p>

          <p className="lg:text-xl md:text-xl text-lg font-bold mt-5 lg:text-right md:text-right">
            -Tiruvalluvar
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThirukuralQuotes;
