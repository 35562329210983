import React from "react";
import Header from "../Components/AboutUs/Header";
import AboutUs from "../Components/AboutUs/AboutUs";
import OurMissionOurVision from "../Components/AboutUs/OurMissionOurVision";
import NavBar from "../Components/NavBar/NavBar";
import AboutDirector from "../Components/AboutUs/AboutDirector";
import Footer from "../Components/Footer/Footer";

import { Helmet } from "react-helmet";

const AboutUsScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Senvira - About Us</title>
      </Helmet>
      <NavBar />
      <Header />
      <AboutUs />
      <OurMissionOurVision />
      <AboutDirector />
      <Footer />
    </div>
  );
};

export default AboutUsScreen;
