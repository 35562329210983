import React, { useState } from "react";
import WorkShopModal from "./WorkShopModal";

// import Image1 from "../../Assets/Image/Workshop/Workshop1/1.jpg";
import Image2 from "../../Assets/Image/Workshop/Workshop1/2.jpg";
import Image3 from "../../Assets/Image/Workshop/Workshop1/3.jpg";
import Image4 from "../../Assets/Image/Workshop/Workshop1/4.jpg";
import Image5 from "../../Assets/Image/Workshop/Workshop1/5.jpg";
import Image6 from "../../Assets/Image/Workshop/Workshop1/6.jpg";
import Image7 from "../../Assets/Image/Workshop/Workshop1/7.jpg";
import Image8 from "../../Assets/Image/Workshop/Workshop1/8.jpg";
import Image9 from "../../Assets/Image/Workshop/Workshop1/9.jpg";
import Image10 from "../../Assets/Image/Workshop/Workshop1/10.jpg";

const data = [
  {
    name: "Designing Resilience: A Specialized Workshop on Reinforced Concrete Structures",
    date: "10-11-2022",
    description:
      "Conducted a two days’ workshop on “Designing Resilience: A Specialized Workshop on Reinforced Concrete Structures” for engineers and contractors in collaboration with TATA TISCON, TATA Steel, VNC and NIT Tiruchirappalli.",
    images: [
      // Image1,
      Image2,
      Image3,
      Image4,
      Image5,
      Image6,
      Image7,
      Image8,
      Image9,
      Image10,
    ],
  },
  // {
  //   name: "Senvira workshop Part-2",
  //   date: "10-11-2022",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto provident nostrum molestiae dicta facilis, non, laboriosam sequi atque facere nemo quaerat odio at accusantium nobis sunt enim excepturi! Officiis, corporis.",
  //   images: [
  //     "https://cdn.prod.website-files.com/62180286278929909e43b116/63a67bdf3d94ad4973cccfda_An%20In%20Person%20Workshop%20Meeting.jpg", // Replace with actual image URLs
  //     "https://www.sessionlab.com/wp-content/uploads/what-is-a-workshop.jpg",
  //     "https://www.georgiaaquarium.org/wp-content/uploads/2023/12/the-home-depot-kids-workshops-at-georgia-aquarium-33-scaled.jpeg",
  //   ],
  // },
];

const RenderCard = ({ onViewImages }) => {
  return (
    <div className="flex flex-col lg:mt-20 md:mt-14 mt-10">
      {data.map((item, index) => (
        <div key={index} className="flex flex-col justify-center items-center">
          <div className="flex lg:flex-row md:flex-col flex-col lg:items-center md:items-start items-start">
            <div className="flex flex-col">
              <h1 className="lg:text-3xl md:text-4xl text-2xl font-Caladea p-2">
                {item.name}
              </h1>
              <p className="lg:text-lg text-base font-Lato font-light p-2 mb-5 lg:w-[70%] md:w-full w-full">
                {item.description}
              </p>
            </div>

            <button
              onClick={() => onViewImages(item.images)}
              className="bg-[#FF0000] text-white font-Lato font-medium h-10 lg:w-56 w-40 rounded-md"
            >
              View Images
            </button>
          </div>

          <hr className="w-full border-t-2 border-gray-300 mt-5" />
        </div>
      ))}
    </div>
  );
};

const WorkShopContent = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalImages, setModalImages] = useState([]);

  const handleViewImages = (images) => {
    setModalImages(images);
    setShowModal(true);
  };

  return (
    <div className="lg:p-20 md:p-10 p-5">
      <div className="flex flex-col justify-center text-center items-center">
        <h1 className="lg:text-4xl md:text-4xl text-3xl font-Caladea tracking-wide font-600 lg:w-[70%] md:w-[80%] w-full">
          Explore the Future of Construction
        </h1>
        <h1 className="lg:text-4xl md:text-4xl text-3xl font-Caladea tracking-wide font-600 lg:w-full md:w-[80%] w-full mt-3">
          Join Our Advanced Structural Design Workshop!
        </h1>

        <p className="lg:text-xl md:text-xl text-base font-Lato lg:mt-10 md:mt-10 mt-5 leading-loose lg:w-[80%] md:w-full w-full">
          This workshop offers a platform to explore the future of construction,
          focusing on advanced topics in structural design. Participants will
          gain practical skills and have the opportunity to network with
          industry leaders.
        </p>

        {/* <p className="lg:text-xl md:text-xl text-base font-Lato font-bold mt-2 leading-loose">
          register today!
        </p> */}
      </div>

      <RenderCard onViewImages={handleViewImages} />
      <WorkShopModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        images={modalImages}
      />
    </div>
  );
};

export default WorkShopContent;
