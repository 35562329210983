import React from "react";

import missionImage from "../../Assets/Image/AboutUs/Mission1.png";
import visionImage from "../../Assets/Image/AboutUs/Vision1.png";

const OurMissionOurVision = () => {
  return (
    <div className="lg:px-20 md:px-10 px-5 mb-10">
      <div>
        <h1 className="lg:text-5xl md:text-5xl text-3xl font-Caladea font-bold tracking-wide text-center">
          Our Mission & Vision
        </h1>
        <div className="flex lg:flex-row md:flex-col flex-col justify-between items-center gap-5 lg:mt-10 md:mt-5 mt-2 py-10">
          <div className="lg:w-[50%] md:w-[100%]">
            <h1 className="lg:text-5xl md:text-5xl text-4xl font-Caladea tracking-wide font-600">
              Our Mission
            </h1>

            <p className="lg:text-2xl md:text-2xl text-lg font-Lato lg:mt-10 md:mt-10 mt-5 md:leading-loose lg:leading-loose text-justify">
              <span className="font-Lato font-bold">Our mission</span> is to
              innovate structural consultancy, integrating cutting-edge tech for
              quality, safe, cost-efficient solutions. With expertise in design,
              assessment, and research, we prioritize resilience,
              sustainability, and client satisfaction, fostering progress in
              engineering and community well-being.
            </p>
          </div>

          <div className="md:mt-5 mt-5 lg:w-[40%] md:w-full w-full">
            <img src={missionImage} alt="images" className="h-auto" />
          </div>
        </div>

        <div className="flex lg:flex-row md:flex-col flex-col justify-between items-center lg:gap-28 md:gap-14 gap-10 mt-10 py-10">
          <div className="md:mt-5 mt-5 lg:w-[40%] md:w-full w-full">
            <img src={visionImage} alt="images" className="h-auto" />
          </div>

          <div className="lg:w-[50%] md:w-[100%]">
            <h1 className="lg:text-5xl md:text-5xl text-4xl font-Caladea tracking-wide font-600">
              Our Vision
            </h1>

            <p className="lg:text-2xl md:text-2xl text-lg font-Lato lg:mt-10 md:mt-10 mt-5 md:leading-loose lg:leading-loose text-justify">
              Our vision is to offer affordable housing solutions to low and
              middle income individuals, ensuring access to essential living
              amenities for all. Through continuous research, collaboration, and
              technological advancement, we aspire to shape the future of
              structural engineering, driving positive change and setting new
              standards of excellence in the industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMissionOurVision;
