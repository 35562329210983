import React, { useState } from "react";
import Modal from "react-modal";
import image1 from "../../Assets/Image/HomeScreen/OurRemarkableProject/1.png";
import image2 from "../../Assets/Image/HomeScreen/OurRemarkableProject/2.png";

const data = [
  {
    projectId: "1",
    title:
      "Structural Inspection and NDT Test for Sri Madhangiswarar Temple Nangur",
    outSideDescription:
      "The Sri Madhangiswarar Temple in Nangur, a historical and cultural landmark, recently underwent a comprehensive structural inspection and non-destructive testing. These evaluations are crucial for preserving the temple's architectural integrity and ensuring the safety of its visitors.",
    image: image1,
    location: "Nangur",
    modalTitle: "Structural Inspection",
    modalDescription1:
      "Our team conducted a detailed structural inspection to assess the condition of the temple's foundation, walls, and roof. This process involved visual examinations, measurements, and documentation of any visible signs of wear, cracks, or other potential issues.",
    modalDescription2:
      "Our commitment to preserving cultural heritage through modern technology helps maintain the timeless beauty and historical significance of the Sri Madhangiswarar Temple. This project highlights the importance of regular inspections and advanced testing in the conservation of historic sites.",
  },
  {
    projectId: "2",
    title: "Non-Destructive Testing for SK Ready Mix Concrete",
    outSideDescription:
      "In this project we ensure the quality of site-casted concrete through advanced non-destructive testing (NDT) methods. These techniques allow us to evaluate the integrity and strength of concrete structures without causing any damage.",
    image: image2,
    location: "",
    modalTitle: "NDT Testing",
    modalDescription1:
      "Using NDT ensures that our concrete structures meet the highest safety and quality standards while preserving their integrity. This proactive approach helps in early detection of potential issues, minimizing maintenance costs and extending the lifespan of the structures.",
    modalDescription2:
      "Our commitment to excellence is demonstrated through our use of state-of-the-art non-destructive testing techniques. We guarantee the reliability and longevity of our site-casted concrete, ensuring your projects stand the test of time.",
  },
];

Modal.setAppElement("#root");

const ProjectDisplay = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProject(null);
  };

  return (
    <div className="lg:px-20 md:px-10 px-5 mb-10">
      <div className="flex lg:flex-row md:flex-col flex-col lg:items-center md:items-start justify-between">
        <div>
          <h1 className="lg:text-5xl md:text-5xl text-3xl font-Caladea tracking-wide font-600">
            Projects
          </h1>
          <p className="lg:text-2xl md:text-xl text-lg font-Lato lg:mt-10 md:mt-5 mt-2 leading-loose text-justify">
            Explore our portfolio of remarkable projects that showcase our
            cutting-edge engineering solutions and their positive impact on the
            communities they serve.
          </p>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:gap-10 md:gap-4 gap-4">
          {data.map((item, index) => (
            <div
              key={index}
              className="flex justify-center lg:mt-10 md:mt-10 mt-5"
            >
              <div className="lg:w-[70%] w-full flex flex-col justify-between border-2 border-black p-5 items-center lg:rounded-xl md:rounded-xl rounded-3xl">
                <div>
                  <img
                    src={item.image}
                    alt="images"
                    style={{ width: "100%" }}
                  />
                  <h1 className="text-2xl font-Caladea p-2">{item.title}</h1>
                </div>
                <p className="text-lg font-Lato mt-2 p-2 mb-5 line-clamp-3">
                  {item.outSideDescription}
                </p>
                <button
                  onClick={() => openModal(item)}
                  className="text-black border-2 border-black font-medium px-5 py-2 rounded-3xl w-full hover:bg-[#FF0000] hover:text-white transition duration-500 ease-in-out"
                >
                  Read more
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedProject && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Project Details"
          className="fixed inset-0 flex items-center justify-center z-50"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
        >
          <div
            className="relative bg-white p-5 w-[90%] max-h-[85vh] overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 m-2 text-3xl md:text-4xl font-bold text-gray-600 hover:text-gray-900"
            >
              &times;
            </button>
            <h2 className="text-3xl font-Caladea font-600 text-center tracking-wide mt-5">
              {selectedProject.title}
            </h2>
            <div className="w-full flex lg:flex-row flex-col items-center mt-5">
              <img
                src={selectedProject.image}
                alt="images"
                className="lg:w-[50%] w-full h-auto mt-4"
              />
              <div className="flex flex-col mt-4">
                <div className="lg:px-5 md:px-3 px-3 mb-5">
                  <p className="lg:text-xl md:text-lg text-base font-Lato">
                    <span className="font-600">Project ID:</span>
                    {"  "}
                    {selectedProject.projectId}
                  </p>
                  {/* <p className="lg:text-xl md:text-lg text-base font-Lato">
                    project title: {selectedProject.title}
                  </p> */}

                  <p className="lg:text-xl md:text-lg text-base font-Lato">
                    <span className="font-600">Location:</span>
                    {"  "}
                    {selectedProject.location}
                  </p>
                </div>
                <p className="lg:text-xl md:text-lg text-base text-justify font-Lato lg:px-5 md:px-3 px-3 mb-3">
                  Work Details:
                </p>
                <p className="lg:text-xl md:text-lg text-base text-justify font-Lato font-600 lg:px-5 md:px-3 px-3 mb-1">
                  {selectedProject.modalTitle}
                </p>
                <p className="lg:text-lg md:text-lg text-base text-justify font-Lato lg:px-5 md:px-3 px-3 mb-2">
                  {selectedProject.modalDescription1}
                </p>
                <p className="lg:text-lg md:text-lg text-base text-justify font-Lato lg:px-5 md:px-3 px-3">
                  {selectedProject.modalDescription2}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ProjectDisplay;
