import React from "react";
import { Link } from "react-router-dom";
import image1 from "../../Assets/Image/HomeScreen/OurRemarkableProject/1.png";
import image2 from "../../Assets/Image/HomeScreen/OurRemarkableProject/2.png";

const data = [
  {
    title:
      "Structural Inspection and NDT Test for sri Madhangiswarar Template Nangur",
    description:
      "The Sri Madhangiswarar Temple in Nangur, a historical and cultural landmark, recently underwent a comprehensive structural inspection and non-destructive testing. These evaluations are crucial for preserving the temple's architectural integrity and ensuring the safety of its visitors.",
    image: image1,
  },
  {
    title: "Non-Destructive Testing for SK Ready Mix Concreate",
    description:
      "In this project we ensure the quality of site-casted concrete through advanced non-destructive testing (NDT) methods. These techniques allow us to evaluate the integrity and strength of concrete structures without causing any damage.",
    image: image2,
  },
];

const OurRemarkableProject = () => {
  return (
    <div className="lg:px-20 md:px-10 px-5 lg:mb-20 md:mb-10 mb-5 lg:mt-0 md:mt-10 mt-14">
      <div className="flex lg:flex-row md:flex-col flex-col lg:items-center md:items-start justify-between">
        <div>
          <h1 className="lg:text-5xl md:text-5xl text-4xl font-Caladea font-600">
            Our Remarkable Projects
          </h1>
          <p className="lg:text-xl md:text-xl text-lg font-Lato lg:mt-10 md:mt-5 mt-5 leading-loose text-justify">
            Explore our portfolio of remarkable projects that showcase our
            cutting-edge <br /> engineering solutions and their positive impact
            on the communities they serve.
          </p>
        </div>

        <Link
          to="/project"
          className="md:mt-5 mt-5 lg:mt-0 lg:mb-0 md:mb-10 mb-5"
        >
          <button className="bg-[#FF0000] text-white font-Lato lg:text-base md:text-sm text-xs lg:px-5 md:px-4 px-3 py-2 rounded-md">
            View more
          </button>
        </Link>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:gap-10 md:gap-4 gap-4">
        {data.map((item, index) => (
          <div key={index} className="flex justify-center lg:mt-10 md:mt-0">
            <div className="lg:w-[90%] md:w-[100%] flex flex-col justify-between border-2 border-black p-5 items-center lg:rounded-xl md:rounded-xl rounded-3xl">
              <div>
                <img src={item.image} alt="images" style={{ width: "100%" }} />
                <h1 className="lg:text-2xl text-xl font-Caladea font-600 p-2 tracking-wider">
                  {item.title}
                </h1>
              </div>
              <p className="text-xl font-Lato font-light mt-2 p-2 mb-5 line-clamp-3">
                {item.description}
              </p>

              {/* <button className="text-black border-2 border-black font-medium px-5 py-2 rounded-3xl mt-auto w-full hover:bg-[#FF0000] hover:text-white transition duration-500 ease-in-out">
                Read more
              </button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurRemarkableProject;
