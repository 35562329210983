import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { useMediaQuery } from "react-responsive";

// Fix default marker icon issue in Webpack
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const Map = () => {
  const SenviraPosition = [13.196593227602666, 80.3157046821943];

  const isMediumScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 576px)" });

  let mapHeight = "80vh"; // Default height
  if (isMediumScreen) {
    mapHeight = "50vh"; // Height for medium screens
  }
  if (isSmallScreen) {
    mapHeight = "30vh"; // Height for small screens
  }

  return (
    <div className="lg:mt-10 md:mt-5">
      <h1 className="lg:text-6xl md:text-6xl text-4xl font-Caladea font-600 tracking-wider text-center md:pb-10 pb-5">
        Our Location
      </h1>
      <MapContainer
        center={SenviraPosition}
        zoom={14}
        style={{ height: mapHeight, width: "100%" }}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={SenviraPosition}>
          <Popup>Senvira</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
