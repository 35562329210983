import React, { useState } from "react";
import image from "../../Assets/Image/Footer/FooterLogo.png";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { MdOutlineEmail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { db } from "../Firebase/FirebaseConfig";
import { setDoc, doc, getDoc } from "firebase/firestore";
import toast, { Toaster } from "react-hot-toast";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = async () => {
    if (!email) {
      toast.error("Please enter a email address. 😕", {
        style: {
          border: "1px solid #fff",
          padding: "16px",
          fontWeight: "bold",
          color: "#fff",
          backgroundColor: "#000",
        },
        iconTheme: {
          primary: "#fff",
          secondary: "#000",
        },
      });
      return;
    }

    if (!validateEmail(email)) {
      // alert("Please enter a valid email address.");
      toast.error("Please enter a valid email address. 😕", {
        style: {
          border: "1px solid #fff",
          padding: "16px",
          fontWeight: "bold",
          color: "#fff",
          backgroundColor: "#000",
        },
        iconTheme: {
          primary: "#fff",
          secondary: "#000",
        },
      });
      return;
    }

    try {
      const emailDoc = doc(db, "SubscriptionUser", email);
      const docSnapshot = await getDoc(emailDoc);

      if (docSnapshot.exists()) {
        // alert("This email is already subscribed.");
        toast.error("This email is already subscribed. 😕", {
          style: {
            border: "1px solid #fff",
            padding: "16px",
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "#000",
          },
          iconTheme: {
            primary: "#fff",
            secondary: "#000",
          },
        });
      } else {
        await setDoc(emailDoc, { email });
        setEmail("");
        toast.success("Thank you for subscribing! 😊", {
          style: {
            border: "1px solid #fff",
            padding: "16px",
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "#000",
          },
          iconTheme: {
            primary: "#fff",
            secondary: "#000",
          },
        });
        // alert("Thank you for subscribing!");
      }
    } catch (e) {
      console.error("Error adding document: ", e);
      toast.error("An error occurred. Please try again later. 😕", {
        style: {
          border: "1px solid #fff",
          padding: "16px",
          fontWeight: "bold",
          color: "#fff",
          backgroundColor: "#000",
        },
        iconTheme: {
          primary: "#fff",
          secondary: "#000",
        },
      });
      // alert("An error occurred. Please try again later.");
    }
  };

  return (
    <footer className="bg-black text-white lg:px-20 md:px-10 px-5 lg:py-14 md:py-14 py-10">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="flex lg:flex-row md:flex-col flex-col justify-between">
        <div className="">
          <h1 className="lg:text-5xl md:text-5xl text-2xl font-Caladea font-600 tracking-wide">
            Join Our Newsletter
          </h1>
          <p className="lg:text-2xl md:text-2xl text-sm lg:mt-5 md:mt-5 mt-2 font-Lato font-light text-[#9F9F9F]">
            We'll send you a newsletter once per week. No spam.
          </p>
        </div>

        <div className="flex gap-5 items-center lg:mt-0 md:mt-10 mt-5">
          <div className="md:w-96 lg:w-96 w-72">
            <input
              type="email"
              placeholder="Enter your email"
              className="bg-[#FFFFFF] text-black lg:p-5 md:p-5 p-4 lg:py-4 md:py-4 py-3 w-full rounded-xl font-Lato"
              style={{ textAlign: "left" }}
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
            />
          </div>

          <div>
            <button
              className="bg-[#FF0000] text-white lg:text-lg md:text-lg text-sm font-Lato lg:px-14 md:px-14 px-6 lg:py-4 md:py-4 py-3 rounded-xl"
              onClick={handleSubscribe}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>

      <div className="mt-10 lg:p-10 md:p-0 p-0">
        <div className="flex lg:flex-row md:flex-col flex-col justify-between">
          <div className="">
            <img
              src={image}
              alt="images"
              className="h-auto"
              style={{ width: "200px" }}
            />

            <div className="">
              <p className="text-lg font-Lato font-light text-white mt-5">
                {/* At Senvira Engineering, we are committed to shaping <br /> the
                future of structural consultancy through innovation, <br /> quality, and unwavering dedication to our
                client's success. */}
                At Senvira Engineering, we are <br /> committed to shaping the
                future of <br /> structural consultancy through <br />
                innovation, quality, and unwavering <br /> dedication to our
                client's success.
              </p>
            </div>

            <div className="flex gap-5 items-center mt-5">
              <div className="border rounded-full border-white p-2 cursor-pointer">
                <FaXTwitter size={12} color="#fff" />
              </div>

              <div className="border rounded-full border-white p-2 cursor-pointer">
                <FaFacebookF size={12} color="#fff" />
              </div>

              <div className="border rounded-full border-white p-2 cursor-pointer">
                <FaInstagram size={12} color="#fff" />
              </div>
            </div>
          </div>

          <div className="lg:mt-0 md:mt-10 mt-5 flex flex-row md:justify-between justify-between lg:gap-72 md:gap-0 gap-4">
            <div className="font-Lato">
              <ul>
                <li className="lg:text-sm md:text-xl font-medium text-[#FF0000]">
                  QUICK LINKS
                </li>
                <li className="lg:text-sm md:text-lg font-normal lg:mt-5 md:mt-5 mt-2 cursor-pointer">
                  <Link to="/">Home</Link>
                </li>
                <li className="lg:text-sm md:text-lg font-normal lg:mt-5 md:mt-5 mt-2 cursor-pointer">
                  <Link to="/about">About us</Link>
                </li>
                <li className="lg:text-sm md:text-lg font-normal lg:mt-5 md:mt-5 mt-2 cursor-pointer">
                  <Link to="/service">Service</Link>
                </li>
                <li className="lg:text-sm md:text-lg font-normal lg:mt-5 md:mt-5 mt-2 cursor-pointer">
                  <Link to="/project">Project</Link>
                </li>
                <li className="lg:text-sm md:text-lg font-normal lg:mt-5 md:mt-5 mt-2 cursor-pointer">
                  <Link to="/clients">Clients</Link>
                </li>
                <li className="lg:text-sm md:text-lg font-normal lg:mt-5 md:mt-5 mt-2 cursor-pointer">
                  <Link to="/workshop">Workshops</Link>
                </li>
                <li className="lg:text-sm md:text-lg font-normal lg:mt-5 md:mt-5 mt-2 cursor-pointer">
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>

            <div>
              <div className="flex flex-col lg:items-center md:items-start items-center font-Lato">
                <h2 className="lg:text-sm md:text-xl font-medium text-[#FF0000]">
                  GET IN TOUCH
                </h2>

                <div className="flex lg:gap-5 md:gap-5 gap-2 items-center lg:mt-5 md:mt-5 mt-2">
                  <GrLocation size={22} color="#FF0000" />

                  <p className="lg:text-base md:text-xl font-light">
                    10/73, 10th Street , <br /> Annai Sivagami <br /> Nagar,
                    Ennore, <br /> Chennai - 600 057.
                  </p>
                </div>

                <div className="flex lg:gap-5 md:gap-5 gap-2 items-center lg:mt-5 md:mt-5 mt-2">
                  <MdOutlineEmail size={22} color="#FF0000" />

                  <p className="lg:text-base md:text-lg font-light">
                    info@senvira.com
                  </p>
                </div>

                <div className="flex lg:gap-5 md:gap-5 gap-2 items-center lg:mt-5 md:mt-5 mt-2">
                  <IoCallOutline size={22} color="#FF0000" />

                  <p className="lg:text-base md:text-lg font-light">
                    +91 86376 89627
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <hr className="w-full mt-5 text-[#A0A0A0]" />
        <p className="text-sm font-light text-[#A0A0A0] mt-10 text-center font-Lato">
          © Copyright {currentYear}, All Rights Reserved by Senvira
        </p>
      </div>
    </footer>
  );
};

export default Footer;
