import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaCircleArrowRight, FaCircleArrowLeft } from "react-icons/fa6";
import { Rating } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import { useMediaQuery } from "react-responsive";

const data = [
  {
    name: "John Doe",
    image:
      "https://png.pngtree.com/png-clipart/20231006/ourmid/pngtree-handsome-businessman-transparent-background-png-image_10194933.png",
    role: "General Manager, TATA Steel",
    comment:
      "“Our team consists of highly skilled professionals with extensive experience in structural engineering and consultancy. Our team consists of highly skilled professionals” ",
    rating: 4,
  },
  {
    name: "Jane Smith",
    image:
      "https://png.pngtree.com/png-clipart/20231006/ourmid/pngtree-handsome-businessman-transparent-background-png-image_10194933.png",
    role: "General Manager, TATA Steel",
    comment:
      "“Our team consists of highly skilled professionals with extensive experience in structural engineering and consultancy. Our team consists of highly skilled professionals” ",
    rating: 5,
  },
  {
    name: "Alice Johnson",
    image:
      "https://png.pngtree.com/png-clipart/20231006/ourmid/pngtree-handsome-businessman-transparent-background-png-image_10194933.png",
    role: "General Manager, TATA Steel",
    comment:
      "“Our team consists of highly skilled professionals with extensive experience in structural engineering and consultancy. Our team consists of highly skilled professionals” ",
    rating: 3,
  },
];

const Whatourclientssays = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const isMdOrSmaller = useMediaQuery({ query: "(max-width: 1024px)" });

  const next = () => {
    setCurrentSlide((prev) => (prev + 1) % data.length);
  };

  const prev = () => {
    setCurrentSlide((prev) => (prev - 1 + data.length) % data.length);
  };

  return (
    <div className="lg:px-20 md:px-10 px-5 lg:mb-20 md:mb-10 mb-5">
      <div className="flex lg:flex-row md:flex-col flex-col lg:items-center md:items-start justify-between ">
        <h1 className="lg:text-5xl md:text-5xl text-4xl font-Caladea font-600">
          What Our Clients Says
        </h1>
        <div className="flex gap-10 mt-5">
          <FaCircleArrowLeft
            size={50}
            color="#FF0000"
            className="cursor-pointer"
            onClick={prev}
          />
          <FaCircleArrowRight
            size={50}
            color="#FF0000"
            className="cursor-pointer"
            onClick={next}
          />
        </div>
      </div>

      <Carousel
        showThumbs={false}
        autoPlay={true}
        centerMode={true}
        showStatus={false}
        showIndicators={false}
        selectedItem={currentSlide}
        onChange={setCurrentSlide}
        centerSlidePercentage={isMdOrSmaller ? 100 : 50}
      >
        {data.map((review, index) => (
          <div key={index} className="px-2 lg:mt-10 md:mt-10 mt-5">
            <div className="flex flex-col bg-[#F5F5F5] p-6 rounded-lg shadow-md w-full">
              <Rating
                value={review.rating}
                readOnly
                className=""
                style={{ maxWidth: 300 }}
                radius="small"
              />
              <p className="text-lg italic text-gray-700 text-start mt-4">
                {review.comment}
              </p>
              <div className="flex flex-row mt-6 items-center">
                <img
                  src={review.image}
                  alt={review.name}
                  style={{ width: "50px", height: "50px" }}
                  className="rounded-full mb-3"
                />
                <div className="text-start ml-4">
                  <h2 className="text-xl font-semibold">{review.name}</h2>
                  <p className="text-gray-500 mb-2">{review.role}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Whatourclientssays;
