import React from "react";
import animationData from "../../Assets/Documents/SplashScreen/Loader.json"; // Replace 'animation.json' with the path to your JSON file
import Lottie from "react-lottie";

const SplashScreen = () => {
  const defaultOptions = {
    // loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div className="flex justify-center items-center h-[100vh] bg-[#fff] bg-opacity-20">
      <Lottie options={defaultOptions} height={500} width={1000} />
    </div>
  );
};

export default SplashScreen;
