import React, { useState } from "react";
import HeaderImage from "../../Assets/Image/HomeScreen/Header/Cover_Page.png";
// import DynarmImage from "../../Assets/Image/HomeScreen/Header/dynarm-logo-200 2.png";
import DynarmLogo from "../../Assets/Image/HomeScreen/Header/dynarm-logo-2.png"
// import TataImage from "../../Assets/Image/HomeScreen/Header/Tata-Tiscon-Logo-Vector-Cdr-download-2048x2000.png";
import TataLogo from "../../Assets/Image/HomeScreen/Header/tata-tiscon-logo.png";
// import AviImage from "../../Assets/Image/HomeScreen/Header/avi_engineering_services_cover.png";
import AviLogo from "../../Assets/Image/HomeScreen/Header/avi_engineering_services_logo.png";
import CPWD from "../../Assets/Image/HomeScreen/Header/cpwd-logo.png";
import { TailSpin } from "react-loader-spinner";

const HeaderHome = () => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <div className="lg:p-20 md:p-10 p-5 lg:w-[80%] md:w-[95%]">
        <h1 className="lg:text-5xl md:text-4xl text-4xl font-Caladea font-600 tracking-wide">
          Crafting the future with innovative structural{" "}
          {/* <span className="text-[#FF0000] font-Pacifico">designs</span> */}
          <span className="text-[#db1822] font-Pacifico">designs</span>
        </h1>

        {/* <p className="lg:text-xl md:text-sm lg:mt-10 md:mt-5 mt-8 text-justify">
          <span className="text-[#FF0000] font-Lato">SENVIRA </span> transform
          the future with groundbreaking structural design. Imagine buildings
          that self-repair, soaring skyscrapers, and homes that evolve with you.
          We push the limits of what's possible, crafting a sustainable,
          resilient, and inspiring built environment.
        </p> */}
      </div>

      <div className="items-center lg:mt-0 md:mt-0 mt-5">
        <div className="w-[90%] m-auto relative">
          {loading && (
            <div className="flex justify-center items-center h-full w-full absolute top-0 left-0 bg-white z-10">
              <TailSpin color="#FF0000" height={50} width={50} />
            </div>
          )}
          <img
            src={HeaderImage}
            alt="HeaderImage"
            className="w-full h-full"
            onLoad={handleImageLoad}
            style={{ display: loading ? "none" : "block" }}
          />
        </div>

        <div className="text-center text-2xl mt-10 font-Caladea font-600">
          Trusted by Global Companies
          <div className="flex lg:flex-row md:flex-row flex-col justify-center lg:mt-10 md:mt-5 mt-5 lg:gap-20 md:gap-7 gap-4 items-center">
            <img
              src={TataLogo}
              alt="TataLogo"
              className="lg:w-32 md:w-12 w-12 lg:h-20 md:h-10 h-8"
            />
            <img
              src={CPWD}
              alt="CPWD"
              className="lg:w-32 md:w-12 w-12 lg:h-24 md:h-10 h-8"
            />
            <img
              src={AviLogo}
              alt="AviLogo"
              className="lg:w-80 md:w-32 w-36 lg:h-12 md:h-6 h-7"
            />
            <img
              src={DynarmLogo}
              alt="DynarmLogo"
              className="lg:w-56 md:w-24 w-20 lg:h-16 md:h-6 h-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderHome;
