import React from "react";
import { Link } from "react-router-dom";

const ContactUsToMakeProjectMemorable = () => {
  return (
    <div className="lg:px-20 md:px-10 px-5 lg:mb-20 md:mb-10 mb-5">
      <div className="flex lg:flex-row md:flex-col flex-col items-center justify-between bg-[#FF5151] lg:p-16 md:p-16 p-8 rounded-3xl">
        <div>
          <h1 className="lg:text-5xl md:text-5xl text-2xl font-Caladea text-white tracking-wide leading-normal lg:text-start md:text-center text-center">
            Contact us to make your <br /> project remarkable
          </h1>
        </div>

        <Link
          to="/contact-us"
          className="flex md:gap-5 lg:gap-5 gap-2 lg:mt-0 md:mt-10 mt-5"
        >
          {/* <button className="bg-[#fff] text-[#FF5151] lg:px-16 md:px-16 px-5 lg:py-4 md:py-4 py-2 rounded-md font-bold md:text-xl lg:text-xl text-sm">
            Get Enquiry
          </button> */}

          <button className="bg-[#000] text-white lg:px-16 md:px-16 px-5 lg:py-4 md:py-4 py-2 rounded-md font-Lato font-600 md:text-xl lg:text-xl text-sm">
            Contact us
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ContactUsToMakeProjectMemorable;
