import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
import { getFirestore } from "@firebase/firestore";
// import { getAuth } from "@firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAsbqkRVi2h1y7rnGT3aU7w8EkgRPhfAt8",
  authDomain: "senvira-56ae8.firebaseapp.com",
  projectId: "senvira-56ae8",
  storageBucket: "senvira-56ae8.appspot.com",
  messagingSenderId: "571067769430",
  appId: "1:571067769430:web:3c963cbb31deadc85091f7",
};

const app = initializeApp(firebaseConfig);

// export const Storage_Bucket = getStorage(app);
export const db = getFirestore(app);

// export const auth = getAuth(app);
