import React from "react";
import { PiLightbulbFilament } from "react-icons/pi";
import { LuFactory } from "react-icons/lu";
import image from "../../Assets/Image/HomeScreen/IndustryTrendsetter/Industry Trendsetter.png";

const IndustryTrendsetter = () => {
  return (
    <div>
      <div className="flex lg:flex-row md:flex-col flex-col items-center lg:px-20 md:px-10 px-5 lg:mt-0 md:mt-5 mt-10 justify-between mb-20">
        <div className="lg:w-[40%] md:w-full w-full">
          <img
            src={image}
            alt="images"
            className="lg:w-[100%] md:w-full h-auto"
          />
        </div>

        <div className="lg:p-10 md:p-0 lg:w-[60%] md:w-full w-full lg:mt-0 md:mt-10 mt-10">
          <h1 className="lg:text-5xl md:text-5xl text-4xl font-Caladea font-bold">
            Industry Trendsetter
          </h1>
          <p className="text-xl font-Lato md:mt-8 mt-5 leading-loose text-justify">
            Through relentless innovation and a commitment to engineering
            excellence, we push the boundaries of what's possible. Our team
            stays ahead of the curve, exploring cutting-edge technologies and
            methodologies to deliver groundbreaking solutions that redefine the
            industry.
          </p>

          <hr className="w-full border-t-2 border-gray-300 my-10" />

          <div className="flex md:flex-row lg:flex-row flex-col lg:justify-around md:justify-start gap-10">
            <div className="flex flex-col gap-2 lg:w-full md:w-72 w-full">
              <PiLightbulbFilament
                className="md:text-7xl lg:text-6xl text-7xl"
                color="#FF0000"
              />
              <h2 className="lg:text-3xl md:text:3xl text-4xl font-medium font-Caladea tracking-wide">
                Innovative Leaders
              </h2>
              <p className="lg:text-base md:text-base text-lg font-Lato md:w-3/4 lg:w-full w-full mt-2 text-justify">
                We innovate, not imitate. Relentless pursuit of excellence makes
                us industry pioneers.
              </p>
            </div>

            <div className="flex flex-col gap-2 lg:w-full md:w-72 w-full">
              <LuFactory
                className="md:text-7xl lg:text-6xl text-7xl"
                color="#FF0000"
              />
              <h2 className="lg:text-3xl md:text:3xl text-4xl font-medium font-Caladea tracking-wide lg:mt-0 md:mt-0 mt-2">
                Industry Redefined
              </h2>
              <p className="lg:text-base md:text-base text-lg font-Lato md:w-3/4 lg:w-full w-full mt-2 text-justify">
                Our cutting-edge solutions redefine expectations, pushing the
                boundaries of what's possible in engineering
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryTrendsetter;
