import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import toast, { Toaster } from "react-hot-toast";

const GetInTouch = () => {
  // const [state, handleSubmit] = useForm("movajbvj"); // Senvira2021 Account
  const [state, handleSubmit] = useForm("mvgpzdgg");
  const [isChecked, setIsChecked] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);

    // Email validation regex
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    setPhone(value);

    // Basic phone number validation
    if (value && value.length < 10) {
      setPhoneError("Invalid phone number");
    } else {
      setPhoneError("");
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const isFormValid = () => {
    return !emailError && !phoneError && email && phone && message && isChecked;
  };

  useEffect(() => {
    if (state.succeeded) {
      toast.success("Message sent successfully!", {
        style: {
          border: "1px solid #000",
          padding: "16px",
          fontWeight: "bold",
          color: "#000",
          backgroundColor: "#FFE1E1",
        },
        iconTheme: {
          primary: "#fff",
          secondary: "#000",
        },
      });

      // Reset form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setIsChecked(false);
      setEmailError("");
      setPhoneError("");
    }
  }, [state.succeeded]);

  return (
    <div className="lg:p-20 md:p-10 p-5">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="flex lg:flex-row md:flex-col flex-col items-center justify-center lg:gap-32 md:gap-28 gap-16">
        <div>
          <h1 className="lg:text-4xl md:text-4xl text-3xl font-Caladea font-600 tracking-wide text-[#FF0000]">
            Get in touch
          </h1>
          <p className="lg:text-2xl md:text-2xl text-xl font-light mt-5 text-gray-400">
            Our friendly team would love to hear from you.
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mt-10 flex gap-5">
              <div className="w-[50%]">
                <label className="block text-sm font-Lato text-gray-700">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  placeholder="First Name"
                  className="bg-[#FFFFFF] text-black p-5 py-4 w-full rounded-md border-2 border-[#D0D5DD] font-Lato"
                  required
                />
                <ValidationError
                  prefix="First Name"
                  field="firstName"
                  errors={state.errors}
                />
              </div>
              <div className="w-[50%]">
                <label className="block text-sm font-Lato text-gray-700">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={handleLastNameChange}
                  placeholder="Last Name"
                  className="bg-[#FFFFFF] text-black p-5 py-4 w-full rounded-md border-2 border-[#D0D5DD] font-Lato"
                  required
                />
                <ValidationError
                  prefix="Last Name"
                  field="lastName"
                  errors={state.errors}
                />
              </div>
            </div>

            <div className="mt-5">
              <label className="block text-sm font-Lato text-gray-700">
                Email Address <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email Address"
                className="bg-[#FFFFFF] text-black p-5 py-4 w-full rounded-md border-2 border-[#D0D5DD] font-Lato"
                required
              />
              {emailError && (
                <p className="text-red-500 text-sm">{emailError}</p>
              )}
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </div>

            <div className="mt-5">
              <label className="block text-sm font-Lato text-gray-700">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                name="phone"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="Phone Number"
                className="bg-[#FFFFFF] text-black p-5 py-4 w-full rounded-md border-2 border-[#D0D5DD] font-Lato"
                required
              />
              {phoneError && (
                <p className="text-red-500 text-sm">{phoneError}</p>
              )}
              <ValidationError
                prefix="Phone"
                field="phone"
                errors={state.errors}
              />
            </div>

            <div className="mt-5">
              <label className="block text-sm font-Lato text-gray-700">
                Message <span className="text-red-500">*</span>
              </label>
              <textarea
                name="message"
                value={message}
                onChange={handleMessageChange}
                placeholder="Message"
                className="bg-[#FFFFFF] text-black p-5 py-4 w-full h-28 rounded-md border-2 border-[#D0D5DD] font-Lato"
                required
              ></textarea>
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
            </div>

            <div className="mt-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="privacy"
                  className="form-checkbox h-3 w-3 text-[#FF0000] rounded-3xl"
                  onChange={handleCheckboxChange}
                  checked={isChecked}
                  required
                />
                <span className="ml-2 text-[#667085] text-sm font-thin">
                  Agree to our friendly privacy policy.{" "}
                  <span className="text-red-500">*</span>
                </span>
              </label>
              <ValidationError
                prefix="Privacy"
                field="privacy"
                errors={state.errors}
              />
            </div>

            <div className="mt-5">
              <button
                type="submit"
                className={`w-full font-Lato py-2 rounded-lg transition-colors duration-300 ${
                  isFormValid()
                    ? "bg-[#FF0000] text-white"
                    : "bg-gray-400 text-gray-700"
                }`}
                disabled={state.submitting || !isFormValid()}
              >
                Send Message
              </button>
            </div>
          </form>
        </div>

        <div className="lg:mt-10 md:mt-0">
          <div className="bg-[#FFE1E1] text-black font-medium lg:pl-10 lg:py-24 md:py-16 py-14 lg:px-16 md:px-12 px-8 rounded-xl">
            <h1 className="text-4xl font-Caladea font-600 tracking-wider">
              Contact information
            </h1>

            <div className="mt-10">
              <p className="font-Caladea font-600 text-xl tracking-wider">
                Office Address
              </p>
              <p className="leading-relaxed mt-2 w-48 font-Lato">
                10/73, 10th Street , Annai Sivagami Nagar, Ennore, Chennai - 600
                057.
              </p>
            </div>

            <div className="mt-5">
              <p className="font-Caladea font-600 text-xl tracking-wider">
                Email address
              </p>
              <a
                href="mailto:info@senvira.com"
                className="text-black underline font-Lato"
              >
                info@senvira.com
              </a>
            </div>

            <div className="mt-5">
              <p className="font-Caladea font-600 text-xl tracking-wider">
                Phone Number
              </p>
              <a href="tel:+918637689627" className="text-black font-Lato">
                +91 86376 89627
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
