import React from "react";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/NavBar/NavBar";
import ClientHeader from "../Components/Clients/ClientHeader";
import ClientsLogo from "../Components/Clients/ClientsLogo";

import { Helmet } from "react-helmet";

const ClientsScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Senvira - Clients</title>
      </Helmet>
      <NavBar />
      <ClientHeader />
      <ClientsLogo />
      <Footer />
    </div>
  );
};

export default ClientsScreen;
