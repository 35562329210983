import React from "react";
import { FaRegUser } from "react-icons/fa";
import { TbTargetArrow } from "react-icons/tb";
import { HiOutlineLightBulb } from "react-icons/hi";
import { TfiRulerAlt2 } from "react-icons/tfi";

const WhyChooseUs = () => {
  return (
    <div className="lg:p-20 md:p-10 p-5 lg:mt-5 md:mt-5 mt-10">
      <h1 className="lg:text-5xl md:text-5xl text-4xl font-600 font-Caladea">
        Why Choose Us
      </h1>

      <p className="lg:text-xl md:text-lg lg:mt-10 md:mt-5 mt-5 font-Lato lg:leading-9 leading-8 text-justify">
        At Senvira Engineering, we engineer your vision. Innovation, quality,
        and dedication fuel our commitment to shaping the future of structural
        consultancy. Let's partner to turn your dreams into reality.
      </p>

      <div className="flex flex-wrap justify-between lg:mt-5 mt-0 items-center">
        <div className="flex flex-col gap-5 mt-10 lg:w-72 md:w-[48%] h-64 bg-[#FFE9E9] p-3 rounded-lg">
          <FaRegUser size={50} color="#FF0000" />
          <h2 className="text-2xl font-Caladea font-600 tracking-wide">
            Expertise
          </h2>
          <p className="text-base font-Lato text-justify">
            Our team consists of highly skilled professionals with extensive
            experience in structural engineering and consultancy.
          </p>
        </div>

        <div className="flex flex-col gap-5 mt-10 lg:w-72 md:w-[48%] h-64 bg-[#FFE9E9] p-3 rounded-lg">
          <TbTargetArrow size={50} color="#FF0000" />
          <h2 className="text-2xl font-Caladea font-600 tracking-wide">
            Client-Centric Approach
          </h2>
          <p className="text-base font-Lato text-justify">
            We tailor solutions to meet clients' needs, ensuring close
            collaboration and customized outcomes for them
          </p>
        </div>

        <div className="flex flex-col gap-5 mt-10 lg:w-72 md:w-[48%] h-64 bg-[#FFE9E9] p-3 rounded-lg">
          <HiOutlineLightBulb size={50} color="#FF0000" />
          <h2 className="text-2xl font-Caladea font-600 tracking-wide">
            Innovative Solutions
          </h2>
          <p className="text-base font-Lato text-justify">
            We utilize cutting-edge tech to innovate solutions for modern
            construction projects, meeting challenges effectively.
          </p>
        </div>

        <div className="flex flex-col gap-5 mt-10 lg:w-72 md:w-[48%] h-64 bg-[#FFE9E9] p-3 rounded-lg">
          <TfiRulerAlt2 size={50} color="#FF0000" />
          <h2 className="text-2xl font-Caladea font-600 tracking-wide">
            Commitment to Quality
          </h2>
          <p className="text-base font-Lato text-justify">
            Quality drives us, from concept to delivery, ensuring excellence in
            every aspect of our work.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
