import React from "react";
import Navbar from "../Components/NavBar/NavBar";
import Footer from "../Components/Footer/Footer";
import ProjectHeader from "../Components/Projects/ProjectHeader";
import ProjectDisplay from "../Components/Projects/ProjectDisplay";

import { Helmet } from "react-helmet";

const ProjectScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Senvira - Projects</title>
      </Helmet>
      <Navbar />
      <ProjectHeader />
      <ProjectDisplay />
      <Footer />
    </div>
  );
};

export default ProjectScreen;
