import React from "react";
import image from "../../Assets/Image/AboutUs/Image.png";

const AboutUs = () => {
  return (
    <div className="lg:px-20 md:px-10 px-5 lg:mb-20 md:mb-20 mb-10">
      <div className="flex flex-col justify-center">
        <h1 className="lg:text-5xl md:text-5xl text-4xl font-Caladea font-600">
          About Us
        </h1>
        <p className="lg:text-xl md:text-2xl text-lg font-Lato lg:mt-10 md:mt-5 mt-5 md:leading-loose lg:leading-loose text-justify">
          Senvira Engineering Private Limited is a dynamic structural
          consultancy firm incubated with the "Center for Entrepreneurship
          Development and Incubation" (CEDI), an initiative promoted by the
          esteemed National Institute of Technology, Tiruchirappalli. Our
          inception from this esteemed institution reflects our commitment to
          innovation, excellence, and the nurturing of entrepreneurial spirit.
          With our firm at the helm, clients can trust that their structural
          engineering needs will be met with precision, efficiency, and
          ingenuity
        </p>

        {/* <div className="mt-10">
          <img
            src={image}
            alt="images"
            className="lg:h-auto md:h-[350px] h-[200px]"
          />
        </div> */}
      </div>
    </div>
  );
};

export default AboutUs;
