import React from "react";
import image1 from "../../Assets/Image/HomeScreen/OurPromises/Innovation.png";
import image2 from "../../Assets/Image/HomeScreen/OurPromises/Quality.png";
import image3 from "../../Assets/Image/HomeScreen/OurPromises/Safety.png";
import image4 from "../../Assets/Image/HomeScreen/OurPromises/Economic.png";

const OurPromises = () => {
  return (
    <div className="flex flex-col items-center lg:px-20 md:px-10 px-5 lg:mt-0 md:mt-0 mt-5 mb-20">
      <div>
        <h1 className="lg:text-5xl md:text-5xl text-4xl font-Caladea font-600">
          Our Promises`
        </h1>
        <p className="text-xl font-Lato md:mt-8 mt-5 leading-loose text-justify">
          We push the boundaries of structural design to create buildings that
          are stronger, more sustainable, and more inspiring. Let us help you
          craft a future-proofed structure that meets your unique needs and
          exceeds your expectations.
        </p>
      </div>

      {/* <div className="flex justify-between items-center flex-row flex-wrap gap-5 mt-14">
        <img
          src={image1}
          alt="images"
          className="lg:w-[23%] md:w-[48%] h-auto"
        />
        <img
          src={image2}
          alt="images"
          className="lg:w-[23%] md:w-[48%] h-auto"
        />
        <img
          src={image3}
          alt="images"
          className="lg:w-[23%] md:w-[48%] h-auto"
        />
        <img
          src={image4}
          alt="images"
          className="lg:w-[23%] md:w-[48%] h-auto"
        />
      </div> */}
      <div className="flex md:justify-center lg:justify-between items-center flex-row flex-wrap gap-5 mt-14">
        <img
          src={image1}
          alt="images"
          className="lg:w-[23%] md:w-[48%] h-auto"
        />
        <img
          src={image2}
          alt="images"
          className="lg:w-[23%] md:w-[48%] h-auto"
        />
        <img
          src={image3}
          alt="images"
          className="lg:w-[23%] md:w-[48%] h-auto"
        />
        <img
          src={image4}
          alt="images"
          className="lg:w-[23%] md:w-[48%] h-auto"
        />
      </div>

    </div>
  );
};

export default OurPromises;
