import React from "react";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/NavBar/NavBar";
import WorkShopHeader from "../Components/Workshop/WorkShopHeader";
import WorkShopContent from "../Components/Workshop/WorkShopContent";

import { Helmet } from "react-helmet";

const WorkshopScreen = () => {
  return (
    <div>
      <Helmet>
        <title>Senvira - Workshop</title>
      </Helmet>
      <NavBar />
      <WorkShopHeader />
      <WorkShopContent />
      <Footer />
    </div>
  );
};

export default WorkshopScreen;
